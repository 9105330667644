export const useMixpanel = () => {
  const { $mixpanel } = useNuxtApp()
  const $store = useAuthStore()
  const { getAccessToken, getIsEnter } = storeToRefs($store)
  const $route = useRoute()

  const generateFunnelId = () => {
    const timestamp = Date.now()
    const randomPart = Math.floor(Math.random() * 100000)
    return Number(`${timestamp}${randomPart}`)
  }

  const createOrTakeFunnelId = (val) => {
    const token = localStorage.getItem(`${val}_funnel_id`)
    if (token) {
      return token
    } else {
      const newToken = generateFunnelId()
      localStorage.setItem(`${val}_funnel_id`, newToken)
      return newToken
    }
  }

  const removeFunnelId = (val) => {
    localStorage.removeItem(`${val}_funnel_id`)
  }

  const enterApp = () => {
    if (getIsEnter.value) return
    $mixpanel.track('enter_app', {
      logged_in: !!getAccessToken.value,
      url: $route.fullPath,
    })
    $store.changeIsEnter()
  }

  const tracker = (trackName, payload) => {
    $mixpanel.track(trackName, payload)
  }

  const trackerLogOut = () => {
    removeFunnelId('sign_up')
    $mixpanel.track('log_out', { logged_in: false })
  }

  const trackerGoToSignUp = (place) => {
    const signUpToken = createOrTakeFunnelId('sign_up')
    $mixpanel.track('sign_up_page_visit', {
      from_where: place,
      sign_up_funnel_id: signUpToken,
    })
  }

  const signUpTracker = ({ email, subToEmails }) => {
    $mixpanel.track('sign_up', {
      sign_up_funnel_id: createOrTakeFunnelId('sign_up'),
      sign_in: true,
      email_address: email,
      subscribe_to_emails: subToEmails,
    })
  }

  const trackerGoToPricingPage = (place) => {
    const planSubToken = createOrTakeFunnelId('plan_subscribe')
    const signUpToken = localStorage.getItem('sign_up_funnel_id')
    const selectedStyle = JSON.parse(localStorage.getItem('selected-style'))

    $mixpanel.track('pricing_page_visit', {
      from_where: place,
      plan_subscribe_funnel_id: planSubToken,
      ...(signUpToken ? { sign_up_funnel_id: Number(signUpToken) } : {}),
      ...(selectedStyle
        ? {
            from_style_name: selectedStyle?.name,
            from_style_id: selectedStyle?.id,
          }
        : {}),
    })
  }

  const trackerGoToCreate = (place) => {
    const signUpToken = createOrTakeFunnelId('sign_up')
    const createImgToken = createOrTakeFunnelId('create_image')
    $mixpanel.track('create_page_visit', {
      from_where: place,
      sign_up_funnel_id: signUpToken,
      create_image_funnel_id: createImgToken,
    })
  }

  return {
    tracker,
    trackerGoToSignUp,
    trackerGoToPricingPage,
    trackerLogOut,
    removeFunnelId,
    trackerGoToCreate,
    createOrTakeFunnelId,
    enterApp,
    signUpTracker,
  }
}
